'use client';

import { Button, Flex, Text } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect } from 'react';
import { Home, RefreshCcw } from 'react-feather';
import DevTeam from '../icons/Paper/DevTeam';
export default function ErrorBoundary({
  error,
  pagename
}: {
  error: Error & {
    digest?: string;
  };
  pagename: string;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  const handleHardRefresh = () => {
    window.location.reload();
  };
  return <Flex flexDirection="column" alignItems="center" justifyContent="center" height="50vh" userSelect="none" data-sentry-element="Flex" data-sentry-component="ErrorBoundary" data-sentry-source-file="index.tsx">
      <Flex direction="column" w="100%" align="center" justify="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <DevTeam data-sentry-element="DevTeam" data-sentry-source-file="index.tsx" />
        <Text userSelect="none" my="2" fontSize={{
        base: '20px',
        lg: '24px'
      }} textAlign="center" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          Oh.
        </Text>

        <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Text userSelect="none" mb="2" mt="2rem" textAlign="center" fontSize={{
          base: '14px',
          lg: '16px'
        }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
            Something has gone wrong on the {pagename} page.
          </Text>

          <Flex align="center" justify="center" mt="2" textAlign="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <Text fontSize={{
            base: '14px',
            lg: '16px'
          }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
              The error has been logged for the development team to review.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex direction="row" w="100%" justify="center" align="center" fontSize="14px" mt="1.5rem" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Flex mx={{
        base: '1',
        lg: '6'
      }} direction={{
        base: 'column',
        lg: 'row'
      }} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Button variant="universalpawg" p="2" w="250px" height="45px" aria-label="Hard Refresh" onClick={handleHardRefresh} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <Flex direction="column" justify="center" align="center" mt="1" p="2" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
              <RefreshCcw size={14} data-sentry-element="RefreshCcw" data-sentry-source-file="index.tsx" />
              Refresh {pagename}
            </Flex>
          </Button>
        </Flex>

        <Flex mx={{
        base: '1',
        lg: '6'
      }} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            {' '}
            <Button w="250px" variant="cosmicpawg" height="45px" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              <Flex direction="column" justify="center" align="center" mt="1" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                <Home size={14} color="#fff" data-sentry-element="Home" data-sentry-source-file="index.tsx" />
                Go Home
              </Flex>
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>;
}