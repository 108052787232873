'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import ErrorBoundary from './components/error';
export default function GlobalError({
  error
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <ErrorBoundary pagename="App" error={error} data-sentry-element="ErrorBoundary" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}